import * as React from 'react';
import styled from 'styled-components';

interface IDownload {
  className?: string;
}

export const Download: React.FC<IDownload> = ({ className }) => {
  return (
    <StyledDownload className={className}>
      <svg
        width="28"
        height="22"
        viewBox="0 0 28 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2566 0.371643L24.97 2.03835L15.6643 11.0903L13.9505 12.7574V12.757V12.7574L12.2375 11.0903L2.93181 2.03835L4.6452 0.371643L13.9505 9.42395L23.2566 0.371643ZM25.5769 14.0192H28V21.0032H25.5769H2.42307H0V18.864V14.0192H2.42307V18.864H25.5769V14.0192Z"
          fill="black"
        />
      </svg>
    </StyledDownload>
  );
};

const StyledDownload: any = styled.div``;
