import * as React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { useQuery } from '@apollo/client';
import { PROPERTY_DOCUMENTS_QUERY } from '../../../../client/__graphql__/queries';

import { Container } from '../../layout/Container';
import { BackgroundLoad } from '../../layout/BackgroundLoad';

import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';

import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';
import NoMatch from '../404Old';
import { Download } from '../../ui/icons/Download';

interface IRoute {
  page: string;
  sguid: string;
}

const Documents: React.FC = () => {
  const params = useParams<{ sguid: string }>();

  const { data, loading: propertyLoading } = useQuery(
    PROPERTY_DOCUMENTS_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          sguid: params.sguid,
        },
      },
    }
  );

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: !propertyLoading && data,
  });

  if (propertyLoading) {
    return null;
  }

  if (!data || !data.getProperty) {
    return <NoMatch />;
  }

  return (
    <>
      <Container
        style={{ ...pageFadeIn, paddingTop: '150px', minHeight: '500px' }}
        className="padding-top"
      >
        <Heading tag="h1" center={true}>
          Dokumenter
        </Heading>
        {data?.getProperty?.documents?.list?.length > 0 ? (
          <DocumentList>
            {data.getProperty.documents.list.map((item: any) => {
              return (
                <Document key={item.id} target="_blank" href={item.url}>
                  <span className="name">{item.name}</span>
                  <span className="icon">
                    <Download />
                  </span>
                </Document>
              );
            })}
          </DocumentList>
        ) : (
          <DocumentList>
            <Paragraph center={true}>Ingen dokumenter ble funnet.</Paragraph>
          </DocumentList>
        )}
      </Container>
      <BackgroundLoad
        showOnMobile={true}
        opacity={0.35}
        url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/hjort_bakside2.jpg"
      />
    </>
  );
};

export default Documents;

const DocumentList = styled.div`
  width: 100%;
`;

const Document = styled.a`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  margin-bottom: 10px;
  background: #18191a;
  color: rgb(255, 255, 255);

  .name {
  }

  .icon {
    width: 20px;
    height: 20px;

    div {
      width: inherit;
      height: inherit;
      svg {
        width: inherit;
        height: inherit;
        path {
          fill: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
